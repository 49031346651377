import React, { Fragment, useEffect, useState } from "react";
import AdminThemeLeft from "../../components/Layouts/AdminLayout/AdminThemeLeft";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiLink, notify, Title } from "../../utils/Title";
export default function AdminPage({ children, active_id }) {
  let [loading, setLoading] = useState(false);
  let [roles, setRoles] = useState(false);
  let [username, setUsername] = useState(false);
  let [showSidebar, setShowSidebar] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    async function checkPermission() {
      const response = await axios.get(
        `${ApiLink.domain + "/check/admin-employee"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        document.title = active_id.substr(6, active_id.length) + Title.origin;
        setLoading(true);
        setRoles(response.data.roles);
        setUsername(response.data.username);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        }
        return navigate("/", { replace: true });
      }
    }
    checkPermission();
  }, [navigate, active_id]);

  const handeleSetShowSideBar = (status) => {
    setShowSidebar(status);
  };

  return (
    <Fragment>
      {!loading ? (
        ""
      ) : (
        <Fragment>
          <div
            onClick={() => {
              handeleSetShowSideBar(!showSidebar);
            }}
            className="icon_bars"
          >
            <i class="fas fa-bars"></i>
          </div>
          <AdminThemeLeft
            roles={roles}
            active_id={active_id}
            showSidebar={showSidebar}
            handeleSetShowSideBar={handeleSetShowSideBar}
          />
          {React.cloneElement(children, {
            username,
          })}
        </Fragment>
      )}
    </Fragment>
  );
}
