import React from "react";
import { Link } from "react-router-dom";

export default function AdminLogo() {
  return (
    <div
      className="logo_vip"
      style={{
        textAlign: "center",
        margin: "5px 0px 10px 0px",
      }}
    >
      <Link to={"/"}>
        <img className="logo_login" src="/images/logo/logo.png" alt="logo" />
      </Link>
    </div>
  );
}
