import React, { Fragment, useEffect, useState } from "react";
import "../../styles/App.css";
import axios from "axios";
import { ApiLink, convertToDateOnly, notify } from "../../utils/Title";
import { Link, useNavigate } from "react-router-dom";
export default function AppPage() {
  const [show, setShow] = useState(-1);
  const [loading, isLoading] = useState(true);
  const [info, setInfo] = useState(null);
  let [mobileAppList, setMobileAppList] = useState([]);
  let navigate = useNavigate();
  let [usernameInput, setUsernameInput] = useState("");
  let [username, setUsername] = useState("");
  useEffect(() => {
    async function getAllMobileApp() {
      const response = await axios.get(`${ApiLink.domain + "/mobile-app"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setMobileAppList(response.data.data); // status, data
      } else {
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/", { replace: true });
        } else if (response.data.must === "permission") {
          notify(false, response.data.message);
          return navigate("/", { replace: true });
        } else {
          return navigate("/404", { replace: true });
        }
      }
    }

    async function getUserInfo() {
      const response = await axios.get(`${ApiLink.domain + "/account/info"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        setUsername(response.data.data.username); // status, data
        setInfo(response.data.data);
      } else {
        if (response.data.must === "login") {
          window.localStorage.clear();
        } else {
          notify(false, response.data.message);
        }
      }
    }

    const getAll = async () => {
      await Promise.all([
        Promise.resolve(getAllMobileApp()),
        Promise.resolve(getUserInfo()),
      ]);
      isLoading(false);
    };
    getAll();
  }, [navigate]);

  const handleLogout = async () => {
    const response = await axios.get(`${ApiLink.domain + "/account/logout"}`, {
      withCredentials: true,
    });
    if (response.data.status) {
      notify(true, response.data.message);
      setUsername("");
      setInfo(null);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        window.localStorage.clear();
      }
    }
  };

  const showTime = () => {
    const d = new Date();
    let hour = d.getHours();
    return hour <= 10
      ? "sáng"
      : hour <= 12
      ? "trưa"
      : hour <= 17
      ? "chiều"
      : "tối";
  };

  const handleViewDetail = (id) => {
    id === show ? setShow(-1) : setShow(id);
  };

  return loading ? (
    <div></div>
  ) : (
    <div className="app_container">
      <div
        className="logo_vip"
        style={{
          textAlign: "center",
          margin: "5px 0px 10px 0px",
        }}
      >
        <Link to={"/"}>
          <img className="logo_login" src="/images/logo/logo.png" alt="logo" />
        </Link>
      </div>
      <div
        style={{
          border: "2px dashed #17a2b8",
          padding: "10px 5px",
          marginBottom: "10px",
        }}
      >
        <div>
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            Chào buổi {showTime()}!
          </b>
          <b
            style={
              username ? { color: "rgb(0, 123, 255)" } : { color: "#c82333" }
            }
          >
            {username ? (
              <span>
                {username} |{" "}
                {info && info.role_id === 1 ? (
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#dc3545",
                    }}
                    to={"/admin/mobile-app"}
                  >
                    Quản Lý
                  </Link>
                ) : (
                  ""
                )}
              </span>
            ) : (
              "Chưa có username"
            )}
          </b>
        </div>
        {info ? (
          <Fragment>
            <div className="text_hello">
              <b
                style={{
                  color: "rgb(40, 167, 69)",
                  marginRight: "5px",
                }}
              >
                Số dư:{" "}
                <span style={{ color: "#007bff" }}>
                  {info.vnd.toLocaleString("vi")} VNĐ
                </span>
              </b>
            </div>
          </Fragment>
        ) : (
          <div className="text_hello">
            <b
              style={{
                color: "rgb(40, 167, 69)",
                marginRight: "5px",
              }}
            >
              Tài khoản:{" "}
              <span style={{ color: "#007bff" }}>
                <Link to="/account/signup">Đăng ký</Link>
                <span
                  style={{
                    margin: "0px 5px",
                  }}
                >
                  /
                </span>
                <Link to="/account/login">Đăng nhập</Link>
              </span>
            </b>
          </div>
        )}
        <div className="text_hello">
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            Kênh Youtube:{" "}
            <Link
              style={{ color: "#007bff", textDecoration: "none" }}
              to={"https://www.youtube.com/c/kiemthecao/videos"}
            >
              Đức Ngọc Kiếm Tiền
            </Link>
          </b>
        </div>{" "}
        <div className="text_hello">
          <b
            style={{
              color: "rgb(40, 167, 69)",
              marginRight: "5px",
            }}
          >
            Zalo:{" "}
            <Link
              to={"https://zalo.me/ducngocx8"}
              style={{ color: "#007bff", textDecoration: "none" }}
            >
              Đức Ngọc
            </Link>
          </b>
        </div>
        {info ? (
          <Fragment>
            <div className="text_hello">
              <b
                style={{
                  color: "rgb(40, 167, 69)",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                Tài khoản:{" "}
                <span
                  onClick={() => {
                    handleLogout();
                  }}
                  style={{ color: "#007bff" }}
                >
                  Đăng xuất
                </span>
              </b>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="input_username_container">
              <b
                style={{
                  color: "rgb(40, 167, 69)",
                  marginRight: "5px",
                  whiteSpace: "nowrap",
                }}
              >
                Điền Username:
              </b>
              <input
                onChange={(e) => {
                  setUsernameInput(e.target.value);
                }}
                className="input_username_app"
                placeholder="Ví dụ: deptrai37"
              />
            </div>

            <div
              className="btn_create_username"
              onClick={() => {
                if (
                  usernameInput.trim() === "" ||
                  usernameInput.trim().length < 3
                ) {
                  notify(
                    false,
                    "Username phải có từ 3 ký tự trở lên! VD ngoc123"
                  );
                } else {
                  setUsername(usernameInput);
                  notify(true, "Tạo username thành công");
                }
              }}
            >
              <span className="btn_create_username-content">Tạo username</span>
            </div>
          </Fragment>
        )}
      </div>

      <div className="list_app">
        {mobileAppList.map((item, index) => {
          return (
            <Fragment>
              <div
                key={index}
                className="one_app"
                style={{
                  marginTop: index === 0 ? "0px" : "10px",
                }}
              >
                <div className="one_app--top">
                  <img
                    className="one_app--top_left"
                    src={item.app_logo}
                    alt="image_app"
                  ></img>
                  <div className="one_app--top_right">
                    <div className="app_title">
                      <img
                        className="app_title_icon"
                        alt="app_title_icon"
                        src="https://kiemthecaofree.com/hot.gif"
                      ></img>
                      <span className="app_title_name">{item.app_name}</span>
                    </div>
                    <div className="app_price">
                      <span className="one_app_child_title">Tiền thưởng:</span>
                      <span className="one_app_child_content">
                        {item.app_price}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Thời gian:</span>
                      <span className="one_app_child_content">
                        {
                          <span
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html:
                                item.Status_App.status_code === "ACTIVE"
                                  ? "Diễn ra từ " +
                                    convertToDateOnly(item.app_start) +
                                    " - " +
                                    convertToDateOnly(item.app_expired)
                                  : item.stop_notify,
                            }}
                          ></span>
                        }
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Hệ điều hành:</span>
                      <span className="one_app_child_content">
                        {item.app_system}
                      </span>
                    </div>

                    <div className="app_price">
                      <span className="one_app_child_title">Yêu cầu:</span>
                      <span className="one_app_child_content">
                        {
                          <span
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: item.app_require,
                            }}
                          ></span>
                        }
                      </span>
                    </div>

                    {item.status_id === 2 ? (
                      <div className="app_download_error">
                        {item.stop_notify}
                      </div>
                    ) : item.app_utm && username === "" ? (
                      <div className="app_download_error">
                        Với App này, bạn cần tạo Username để tải
                      </div>
                    ) : (
                      <div
                        className="app_download"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            item.app_utm
                              ? item.app_first_download +
                                username +
                                item.app_end_download
                              : item.app_first_download
                          }
                          style={{ color: "#fff" }}
                        >
                          Tải App
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="one_app--bottom">
                  <div className="one_app--bottom-top">
                    <p
                      className="one_app--bottom_step_title"
                      style={{ color: "#17a2b8" }}
                    >
                      Các bước thực hiện:
                    </p>
                    <div
                      className="one_app--bottom_step"
                      onClick={() => {
                        handleViewDetail(item.app_id);
                      }}
                    >
                      <img
                        className="icon_down"
                        alt="icon_down"
                        src={
                          show === item.app_id
                            ? "/images/icons/up-arrow.png"
                            : "/images/icons/down-arrow.png"
                        }
                      ></img>
                      <p className="one_app--bottom_step_title">
                        {show === item.app_id
                          ? "Tắt hướng dẫn"
                          : "Xem hướng dẫn"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="one_app--bottom-bottom"
                    style={{
                      display: show === item.app_id ? "block" : "none",
                    }}
                  >
                    {
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: item.app_description,
                        }}
                      ></div>
                    }

                    <div className="app_note">
                      <p className="app_note--title">Lưu ý:</p>
                      <div className="app_note--content">
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{ __html: item.app_note }}
                        ></div>
                      </div>
                    </div>

                    <div className="app_video">
                      <p className="app_video--title">Video hướng dẫn:</p>
                      <iframe
                        className="video_source"
                        width="100%"
                        height="220px"
                        src={item.app_video}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
              {index === mobileAppList.length - 1 ? (
                <div className="end_list">Đã tới cuối danh sách</div>
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
