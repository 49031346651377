import ForgotPasswordLayout from "../components/Layouts/AccountLayout/ForgotPasswordLayout";
import LoginLayout from "../components/Layouts/AccountLayout/LoginLayout";
import ResendEmailLayout from "../components/Layouts/AccountLayout/ResendEmailLayout";
import SignLayout from "../components/Layouts/AccountLayout/SignLayout";
import VerifyEmailLayout from "../components/Layouts/AccountLayout/VerifyEmailLayout";
import MobileAppAdmin from "../components/Layouts/AdminLayout/MobileApp";
import CategoryAdmin from "../components/Layouts/AdminLayout/CategoryAdmin";
import StatusAppAdmin from "../components/Layouts/AdminLayout/StatusAppAdmin";
import RoleAdmin from "../components/Layouts/AdminLayout/RoleAdmin";
import CustomerAdmin from "../components/Layouts/AdminLayout/CustomerAdmin";
import OTPAdmin from "../components/Layouts/AdminLayout/OTPAdmin";
import AdminPage from "../pages/AdminPage";
import AppPage from "../pages/AppPage";
import LoginPage from "../pages/LoginPage";
import UserPage from "../pages/UserPage";
import UserInfo from "../components/Layouts/UserLayout/UserInfo";
import ErrorPage from "../pages/ErrorPage";

const publicRoutes = [
  { path: "/", component: AppPage },
  { path: "/account/login", component: LoginPage, layout: LoginLayout },
  { path: "/account/signup", component: LoginPage, layout: SignLayout },
  { path: "/404", component: ErrorPage },
  {
    path: "/account/lost-password",
    component: LoginPage,
    layout: ForgotPasswordLayout,
  },
  {
    path: "/account/verify/email/:email/:token",
    component: LoginPage,
    layout: VerifyEmailLayout,
  },
  {
    path: "/account/resend-email",
    component: LoginPage,
    layout: ResendEmailLayout,
  },
];

const privateRoutes = [
  {
    path: "/admin/mobile-app",
    component: AdminPage,
    layout: MobileAppAdmin,
    active_id: "ADMIN_MOBILE_APP",
  },
  {
    path: "/admin/category",
    component: AdminPage,
    layout: CategoryAdmin,
    active_id: "ADMIN_CATEGORY",
  },
  {
    path: "/admin/status-app",
    component: AdminPage,
    layout: StatusAppAdmin,
    active_id: "ADMIN_STATUS_APP",
  },
  {
    path: "/admin/customer",
    component: AdminPage,
    layout: CustomerAdmin,
    active_id: "ADMIN_CUSTOMER",
  },
  {
    path: "/admin/role",
    component: AdminPage,
    layout: RoleAdmin,
    active_id: "ADMIN_ROLE",
  },
  {
    path: "/admin/otp",
    component: AdminPage,
    layout: OTPAdmin,
    active_id: "ADMIN_OTP",
  },

  {
    path: "/account/info",
    component: UserPage,
    layout: UserInfo,
    active_id: 1,
  },
];

export { publicRoutes, privateRoutes };
