import React, { Fragment, useEffect, useState } from "react";
import "../../styles/Login.css";
import "../../styles/Flex.css";
import "../../styles/Header.css";
import { useNavigate } from "react-router-dom";
import { ApiLink } from "../../utils/Title";
import axios from "axios";
import AdminLogo from "../../components/Layouts/AdminLayout/AdminLogo";
export default function LoginSignup({ children }) {
  let [loading, isLoading] = useState(true);
  let navigate = useNavigate();
  useEffect(() => {
    async function checkPermission() {
      const response = await axios.get(`${ApiLink.domain + "/check/all"}`, {
        withCredentials: true,
      });
      if (response.data.status) {
        return navigate("/", { replace: true });
      } else {
        if (response.data.must === "login") {
          isLoading(false);
        } else return navigate("/", { replace: true });
      }
    }
    checkPermission();
  }, [navigate]);

  return (
    <Fragment>
      {loading ? (
        <Fragment></Fragment>
      ) : (
        <Fragment>
          {/* <Header />
          <ChildHeader/> */}
          <div className="flex_center signup bg-white">
            <AdminLogo />
            <div className="_1200px flex_center signup_container">
              {children}
              <div
                className="signup_container_right"
                style={{
                  backgroundImage: `url("/images/image/background.jpg")`,
                }}
              ></div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
