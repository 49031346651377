import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../utils/Title";
import { useNavigate } from "react-router-dom";
import FormConfirm from "./FormConfirm";

export default function FormAddMobileApp({
  closeFormAdd,
  loadData,
  itemChoose,
}) {
  let [statusAppList, setStatusAppList] = useState([]);
  let [CategoryList, setCategoryList] = useState([]);
  let [showFormConfirmImage, setShowFormConfirmImage] = useState(false);
  let [imageItemRemove, setImageItemRemove] = useState({
    image_id: -1,
    image_url: "....",
  });
  let navigate = useNavigate();
  let [itemState, setItemState] = useState({
    image_url: "",
    image_note: "",
  });
  let [mobileAppState, setMobileAppState] = useState({
    app_id: itemChoose.app_id,
    app_name: itemChoose.app_name,
    app_description: itemChoose.app_description,
    app_price: itemChoose.app_price,
    app_expired: itemChoose.app_expired,
    app_start: itemChoose.app_start,
    app_first_download: itemChoose.app_first_download,
    app_end_download: itemChoose.app_end_download,
    app_utm: itemChoose.app_utm,
    app_require: itemChoose.app_require,
    app_system: itemChoose.app_system,
    app_code: itemChoose.app_code,
    app_video: itemChoose.app_video,
    stop_notify: itemChoose.stop_notify,
    stop_url_download: itemChoose.stop_url_download,
    priority: itemChoose.priority,
    status_id: itemChoose.status_id,
    category_id: itemChoose.category_id,
    app_logo: itemChoose.app_logo,
    app_icon: itemChoose.app_icon,
    app_note: itemChoose.app_note,
  });

  console.log("mobileAppState", mobileAppState);

  let [imageList, setImageList] = useState([]);
  let [edited, setEdited] = useState(false);

  useEffect(() => {
    async function getAllStatusApp() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/status-app"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setStatusAppList(response.data.data); // status, data
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }

    async function getAllCategory() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/category"}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setCategoryList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }

    async function getImageMobileAppID() {
      const response = await axios.get(
        `${ApiLink.domain + "/admin/image-app/" + itemChoose.app_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        setImageList(response.data.data);
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return;
        }
      }
    }

    const list_promise = [
      Promise.resolve(getAllCategory()),
      Promise.resolve(getAllStatusApp()),
      Promise.resolve(getImageMobileAppID()),
    ];

    Promise.all(list_promise);
  }, [navigate, itemChoose.app_id]);

  const handleOnChange = (e) => {
    let { name, value } = e.target;
    setItemState({ ...itemState, [name]: value });
  };

  function checkURL(url) {
    return url.match(/\.(jpeg|jpg|png)$/) != null;
  }

  const addImageAsync = async (newImage) => {
    const response = await axios.post(
      `${ApiLink.domain + "/admin/image-app"}`,
      newImage,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      setImageList(response.data.data);
      setEdited(true);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleAddImage = async () => {
    const image_url = itemState.image_url.trim();
    const image_note = itemState.image_note.trim();
    if (image_url === "" || !checkURL(image_url)) {
      notify(
        false,
        "Bạn chưa nhập hình ảnh hoặc hình ảnh không hợp lệ (jpeg, jpg, png)"
      );
    } else {
      const newImage = {
        image_url: image_url,
        image_note: image_note,
        app_id: itemChoose.app_id,
      };
      await addImageAsync(newImage);
    }
  };

  const loadDataImage = (data) => {
    setImageList(data);
    setEdited(true);
    setShowFormConfirmImage(false);
  };

  const handleRemoveImage = async (item) => {
    setImageItemRemove(item);
    setShowFormConfirmImage(true);
  };

  const handleChangeMobileAppValue = (e) => {
    let { name, value } = e.target;
    if (name === "category_id" || name === "status_id" || name === "priority") {
      value = Number(value);
    } else if (name === "app_utm") {
      value = String(value).trim() === "true" ? true : false;
    }

    setMobileAppState({ ...mobileAppState, [name]: value });
  };

  const editMobileApp = async (newMobileApp) => {
    const response = await axios.put(
      `${ApiLink.domain + "/admin/mobile-app/" + itemChoose.app_id}`,
      newMobileApp,
      {
        withCredentials: true,
      }
    );
    if (response.data.status) {
      notify(true, response.data.message);
      loadData(response.data.data);
      closeFormAdd(true, edited);
    } else {
      notify(false, response.data.message);
      if (response.data.must === "login") {
        return navigate("/account/login", { replace: true });
      } else if (response.data.must === "permission") {
        return;
      }
    }
  };

  const handleEditMobileApp = async () => {
    if (mobileAppState.app_name.trim() === "") {
      notify(false, "Vui lòng điền tên ứng dụng");
      return;
    } else if (
      mobileAppState.category_id === "" ||
      Number(mobileAppState.category_id === -1)
    ) {
      notify(false, "Vui lòng chọn danh mục ứng dụng");
      return;
    } else if (
      mobileAppState.fish_status === "" ||
      Number(mobileAppState.fish_status === -1)
    ) {
      notify(false, "Vui lòng chọn trạng thái ứng dụng");
      return;
    } else if (mobileAppState.app_logo.trim() === "") {
      notify(false, "Vui lòng thêm hình ảnh Logo ứng dụng");
      return;
    } else if (mobileAppState.app_price.trim() === "") {
      notify(false, "Vui lòng nhập tiền thưởng");
      return;
    } else if (mobileAppState.app_system.trim() === "") {
      notify(false, "Vui lòng nhập thiết bị hỗ trợ");
      return;
    }
    const newMobileApp = {
      app_name: mobileAppState.app_name.trim(),
      app_description: mobileAppState.app_description.trim(),
      app_price: mobileAppState.app_price.trim(),
      app_expired: mobileAppState.app_expired,
      app_start: mobileAppState.app_start,
      app_first_download: mobileAppState.app_first_download.trim(),
      app_end_download: mobileAppState.app_end_download.trim(),
      app_utm: String(mobileAppState.app_utm).trim() === "true" ? true : false,
      app_require: mobileAppState.app_require.trim(),
      app_system: mobileAppState.app_system.trim(),
      app_code: mobileAppState.app_code.trim(),
      app_video: mobileAppState.app_video.trim(),
      stop_notify: mobileAppState.stop_notify.trim(),
      stop_url_download: mobileAppState.stop_url_download.trim(),
      priority: Number(mobileAppState.priority),
      status_id: Number(mobileAppState.status_id),
      category_id: Number(mobileAppState.category_id),
      app_logo: mobileAppState.app_logo.trim(),
      app_icon: mobileAppState.app_icon.trim(),
      app_note: mobileAppState.app_note.trim(),
    };
    await editMobileApp(newMobileApp);
  };

  const closeFormConfirm = () => {
    setShowFormConfirmImage(false);
  };

  return (
    <div
      className="row col-9 add_edit_class"
      style={{
        display: "block",
        backgroundColor: "rgb(242, 247, 255)",
        // borderRadius: "3px 3px 0px 0px",
        boxShadow:
          "rgb(98, 176, 253) 0px 4px 8px, rgba(0, 0, 0, 0.08) 0px 4px 12px",
        padding: "0px !important",
        margin: 0,
      }}
    >
      {showFormConfirmImage ? (
        <div className="background_black_child" style={{ display: "block" }}>
          {showFormConfirmImage ? (
            <FormConfirm
              itemChoose={mobileAppState}
              status={"admin_edit_delete_image"}
              content={
                "Xác nhận xóa hình ảnh của ứng dụng " +
                mobileAppState.app_name +
                " với ID = "
              }
              id_handle={imageItemRemove.image_id}
              closeFormConfirm={closeFormConfirm}
              loadData={loadDataImage}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div
        className="bg-primary row"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 20px",
          margin: 0,
        }}
      >
        <div className="text-white add_book_class_header">
          Cập Nhật Thông Tin ứng dụng
        </div>
        <div onClick={() => closeFormAdd(false, edited)}>
          <i
            className="far fa-times-circle btn_close_form"
            style={{ color: "white", fontSize: 25 }}
          />
        </div>
      </div>

      <div
        className="row col-12 sroll_form style-15 mobile_min_width_100 mobile_no_wrap"
        style={{
          padding: "0px !important",
          display: "flex",
          flexDirection: "row",
          margin: 0,
        }}
      >
        <div className="row col-8 mobile_min_width_100">
          <div
            className="row col-12 mobile_min_width_100"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
              display: "flex",
            }}
          >
            <div className="col-6 mobile_min_width_100">
              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Tên ứng dụng</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control showordisable"
                  name="app_name"
                  type="text"
                  defaultValue={mobileAppState.app_name}
                  placeholder="VD: VNPAY Đăng Ký Tài Khoản"
                />
              </div>
              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Danh mục</label>
                <select
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-select"
                  name="category_id"
                  value={mobileAppState.category_id}
                >
                  {CategoryList.map((category, index) => {
                    return (
                      <option key={index} value={category.category_id}>
                        {category.category_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Tiền thưởng</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="app_price"
                  type="text"
                  defaultValue={mobileAppState.app_price}
                  placeholder="VD: 30K công + 50K Từ App"
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Mã giới thiệu</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="app_code"
                  type="text"
                  placeholder="VD: THECAO"
                  defaultValue={mobileAppState.app_code}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Ngày bắt đầu</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control showordisable"
                  name="app_start"
                  type="date"
                  defaultValue={mobileAppState.app_start}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Thứ tự ưu tiên</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="priority"
                  type="text"
                  placeholder="VD: 1 - Thứ tự càng nhỏ thì ưu tiền càng cao"
                  defaultValue={mobileAppState.priority}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Ảnh Logo</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control showordisable"
                  name="app_logo"
                  type="text"
                  placeholder="Đường dẫn hình ảnh Logo"
                  defaultValue={mobileAppState.app_logo}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Link bắt đầu</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="app_first_download"
                  type="text"
                  defaultValue={mobileAppState.app_first_download}
                  placeholder="Nhập link download bắt đầu"
                />
              </div>
            </div>
            <div className="col-6 mobile_min_width_100">
              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Trạng thái</label>
                <select
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-select"
                  name="status_id"
                  value={mobileAppState.status_id}
                >
                  {statusAppList.map((status, index) => {
                    return (
                      <option key={index} value={status.status_id}>
                        {status.status_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginBottom: 20 }}>
                <label className="form-label">UTM</label>
                <select
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="app_utm"
                  defaultValue={mobileAppState.app_utm}
                >
                  <option value={true}>Cho phép</option>
                  <option value={false}>Không cho phép</option>
                </select>
              </div>
              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Thiết bị</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="app_system"
                  defaultValue={mobileAppState.app_system}
                  type="text"
                  placeholder="VD: Android + IOS"
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Video Youtube</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control showordisable"
                  name="app_video"
                  type="text"
                  placeholder="Nhập đường dẫn video HD"
                  defaultValue={mobileAppState.app_video}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Ngày kết thúc</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control showordisable"
                  name="app_expired"
                  type="date"
                  defaultValue={mobileAppState.app_expired}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Link tạm ngưng</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="stop_url_download"
                  type="text"
                  placeholder="Đường dẫn khi chương trình dừng"
                  defaultValue={mobileAppState.stop_url_download}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Ảnh Icon</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control showordisable"
                  name="app_icon"
                  type="text"
                  placeholder="Đường dẫn hình ảnh Icon"
                  defaultValue={mobileAppState.app_icon}
                />
              </div>

              <div style={{ marginBottom: 20 }}>
                <label className="form-label">Link kết thúc</label>
                <input
                  onChange={(e) => handleChangeMobileAppValue(e)}
                  className="form-control"
                  name="app_end_download"
                  type="text"
                  defaultValue={mobileAppState.app_end_download}
                  placeholder="Nhập link download kết thúc"
                />
              </div>
            </div>
            <div
              className="row col-12 mobile_min_width_100"
              style={{
                marginLeft: "0px !important",
                marginRight: "0px !important",
                marginBottom: 20,
              }}
            >
              <label className="form-label">Yêu cầu</label>
              <textarea
                onChange={(e) => handleChangeMobileAppValue(e)}
                className="form-control style-15"
                name="app_require"
                defaultValue={mobileAppState.app_require}
                type="text"
                rows="2"
                placeholder="VD: Yêu cầu từ 15 tuổi trở lên"
              />
            </div>

            <div
              className="row col-12 mobile_min_width_100"
              style={{
                marginLeft: "0px !important",
                marginRight: "0px !important",
                marginBottom: 20,
              }}
            >
              <label className="form-label">Mô tả</label>
              <textarea
                onChange={(e) => handleChangeMobileAppValue(e)}
                className="form-control style-15"
                name="app_description"
                defaultValue={mobileAppState.app_description}
                type="text"
                rows="3"
                placeholder="VD: Các bước làm và hình ảnh nếu có"
              />
            </div>

            <div
              className="row col-12 mobile_min_width_100"
              style={{
                marginLeft: "0px !important",
                marginRight: "0px !important",
                marginBottom: 20,
              }}
            >
              <label className="form-label">Lưu ý</label>
              <textarea
                onChange={(e) => handleChangeMobileAppValue(e)}
                className="form-control style-15"
                name="app_note"
                defaultValue={mobileAppState.app_note}
                type="text"
                rows="3"
                placeholder="VD: Ghi chú nếu có"
              />
            </div>

            <div
              className="row col-12 mobile_min_width_100"
              style={{
                marginLeft: "0px !important",
                marginRight: "0px !important",
                marginBottom: 20,
              }}
            >
              <label className="form-label">Thông báo ngưng</label>
              <textarea
                onChange={(e) => handleChangeMobileAppValue(e)}
                className="form-control style-15"
                name="stop_notify"
                defaultValue={mobileAppState.stop_notify}
                type="text"
                rows="3"
                placeholder="VD: Thông báo chương trình tạm ngừng"
              />
            </div>
          </div>
        </div>
        <div
          className="row col-4 mobile_min_width_100_vfix"
          style={{ padding: 20, display: "flex" }}
        >
          <div
            className="row"
            style={{
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div style={{ marginBottom: 20 }}>
              <label className="form-label">- Hình Ảnh ứng dụng</label>
              <div className="image_group">
                {imageList.map((image, index) => {
                  return (
                    <span key={index} className="one_image">
                      <img src={image.image_url} alt="anh" />
                      <span
                        onClick={() => handleRemoveImage(image)}
                        className="remove_image"
                      >
                        X
                      </span>
                    </span>
                  );
                })}
              </div>
              <div style={{ margin: "5px 0" }}>
                <input
                  onChange={(e) => handleOnChange(e)}
                  className="form-control showordisable noborderRadius"
                  name="image_note"
                  type="text"
                  placeholder="Mô tả cho hình ảnh"
                />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  onChange={(e) => handleOnChange(e)}
                  className="form-control showordisable noborderRadius"
                  name="image_url"
                  type="text"
                  placeholder="Đường dẫn hình ảnh"
                />

                <button
                  onClick={() => handleAddImage()}
                  style={{
                    padding: "0.45rem 0.75rem",
                  }}
                  className="btn btn-success noborderRadius"
                >
                  Thêm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        <button
          onClick={() => handleEditMobileApp()}
          className="btn btn-success btn_add_edit_customer_submit"
        >
          Cập nhật ứng dụng
        </button>
      </div>
    </div>
  );
}
